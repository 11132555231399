.CategoriesItem {
  display: flex;
  align-items: stretch;
  align-content: stretch;
}

.CategoriesItem-icon-left {
  padding: 16px 16px 16px 24px;
}

.CategoriesItem-title {
  flex-grow: 1;
  padding: 16px 0;
  align-self: center;
}

.CategoriesItem-icon-right {
  opacity: 0.5;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  align-content: center;
}
