/* .MenuMobile {} */

.MenuMobile-panel {
  position: fixed;
  top: 7.6%;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  background: white;
  flex-direction: column;
  z-index: 0;
  transform: translateY(
    calc(100% - 68px - (env(safe-area-inset-bottom) * 2.5))
  );
  transition: transform 0.35s ease-in-out;
  will-change: transform;
}

.MenuMobile-panel.-availableSpace {
  /* This div is used for available space calculations */
  pointer-events: none;
  visibility: hidden;
  z-index: -1000;
  transform: translateY(60%);
}

.MenuMobile-panel.-categories {
  z-index: 1;
  box-shadow: 0 5em 6em 3em black;
}

.MenuMobile-panel.-active {
  z-index: 1;
  transform: translateY(0%);
  box-shadow: 0 5em 6em 3em black;
}

.MenuMobile-panel.-active.-partial {
  z-index: 1;
  transform: translateY(60%);
}

.MenuMobile-panel.-active {
  z-index: 1;
}

.MenuMobile-panel-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  z-index: 1;
  position: relative;
  background: white;
}

.MenuMobile-panel-header-icon-left {
  padding: 24px 20px;
  cursor: pointer;
}

.MenuMobile-panel-header-title {
  padding: 24px 20px;
  flex-grow: 1;
  font-size: 18px;
  font-weight: 500;
}

.MenuMobile-panel-header-icon-right {
  padding: 24px 20px;
  transition: transform 0.25s ease-in-out;
  cursor: pointer;
}

.MenuMobile-panel.-active.-partial .MenuMobile-panel-header-icon-right {
  transform: rotate(0deg);
}

.MenuMobile-panel.-active .MenuMobile-panel-header-icon-right {
  transform: rotate(180deg);
}

.MenuMobile-panel-body {
  position: relative;
  overflow: auto;
  flex-grow: 0;
  transition: all 0.5s ease;
  max-height: 100%;
  z-index: 2;
  background: white;
}

.MenuMobile-panel.-partial .MenuMobile-panel-body {
  max-height: calc(37vh - 66px);
}

.MenuMobile-panel-body > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.MenuMobile-panel-body > ul > li {
  border-bottom: 1px solid #cccccc;
  cursor: pointer;
}

.MenuMobile-panel-body > ul > li:hover {
  background: #f5f5f5;
}

.Zoom {
  z-index: 11;
  display: flex;
  position: absolute;
  flex-direction: column;
  top: initial;
  right: 8px;
  transform: translateY(0%);
  opacity: 1;
  transition: transform 0.3s ease-in-out;
}

.Zoom.-CATEGORIES {
  bottom: calc(100% + 8px);
}

.Zoom.-CATEGORY {
  bottom: calc(100% + 8px);
}

.Zoom.-ITEM {
  bottom: calc(100% + 8px);
}

.MenuMobile-panel.-active.-full .Zoom.-CATEGORIES,
.MenuMobile-panel.-active.-full .Zoom.-CATEGORY,
.MenuMobile-panel.-active.-full .Zoom.-ITEM {
  z-index: -1;
  opacity: 1;
  transform: translateY(110%);
}
