.Area {
  position: absolute;
}

.Area svg * {
  transition: all 0.02s ease-in-out;
}

.Area-icons {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1;
  overflow: visible;
}
