html,
body,
#root,
.App {
  height: 100%;
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
}

body {
  font-family: "Roboto", sans-serif;
}

.App {
  display: flex;
  z-index: 0;
  position: relative;
}

@media screen and (max-width: 991px) {
  .App {
    flex-direction: column;
  }
}

.AppHeader-mobile {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background: white;
  z-index: 1;
  font-weight: 500;
  font-size: 18px;
  border-bottom: solid 1px #cccccc;
}

.AppHeader-mobile > * {
  padding: 16px 24px;
}

.AppHeader-mobile img {
  display: inline-block;
}

.App-secondary {
  width: 28.88%;
  height: 100vh;
  z-index: 2;
  position: relative;
}

@media screen and (max-width: 991px) {
  .App-secondary {
    position: absolute;
    height: auto;
    bottom: 0;
    width: 100%;
  }
}

.App-primary {
  flex-grow: 1;
  overflow: hidden;
  z-index: 0;
  position: relative;
}

.on-map-buttons {
  background-color: #fff;
  border-radius: 3px;
  font-size: 19px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
  height: 38px;
  width: 38px;
  margin: 4px;
  padding: 0;
  transition: all ease-in-out 250ms;
  color: rgb(130 130 130);
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .on-map-buttons.-reset {
    display: none;
  }
}

.on-map-buttons:hover {
  color: #000;
  background-color: rgb(250, 250, 250);
}

.on-map-buttons:disabled,
.on-map-buttons:disabled:hover {
  background-color: #fff;
  color: rgb(206, 206, 206);
  cursor: default;
}

.map-button-wrapper {
  z-index: 23;
  display: flex;
  position: absolute;
  padding: 8px;
}

.position-map-top-right {
  top: 0;
  right: 8px;
}

.position-map-top-left {
  top: 0;
  left: 8px;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

@media screen and (min-width: 991px) {
  .Zoom {
    z-index: 11;
    display: flex;
    position: absolute;
    top: 8px;
    right: 8px;
    flex-direction: column;
  }
}

.mapCanvas {
  will-change: transform;
  position: absolute;
  z-index: 10;
  background-color: #f6f6f6;
}

.mapCanvas-zoom {
  transform-origin: center center;
  transition: transform 300ms ease-in-out;
}

.mapCanvas-sidewalks {
  position: absolute;
  top: 22.52%;
  left: 39.2%;
  z-index: 1;
  pointer-events: none;
}

.mapCanvas-trails {
  position: absolute;
  top: 55.52%;
  left: 58.79%;
  z-index: 1;
  pointer-events: none;
}

button {
  background-color: #005ee3;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 16px;
  height: 32px;
  font-family: "Roboto", sans-serif;
}
