.IconCategory {
  width: 18px;
  height: 18px;
  overflow: visible;
  margin: 2px;
}

.IconCategory svg {
  width: 100%;
  height: 100%;
  overflow: visible;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.IconCategory svg circle {
  stroke-width: 2px;
  stroke: #ffffff;
}
