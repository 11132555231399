.Menu {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: white;
  box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.Menu-header {
  padding: 18px 24px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
}

.Menu-header h1 {
  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  line-height: 1;
}

.Menu-body {
  overflow: auto;
}
