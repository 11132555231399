.Item {
  padding: 24px;
}

.Item > *:first-child {
  margin-top: 0;
}

.Item > *:last-child {
  margin-bottom: 0;
}

.Item p {
  display: flex;
  align-items: flex-start;
}

.Item p img {
  margin-right: 12px;
}
