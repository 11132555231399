.SubMenuItem {
  padding: 16px 24px;
}

.SubMenuItem > * + * {
  margin-top: 8px;
}

.SubMenuItem h1 {
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  line-height: 1;
  margin: 0;
}

.SubMenuItem ul {
  display: flex;
  list-style-type: none;
  color: #444444;
  padding-inline-start: 0;
  align-items: center;
  margin-bottom: 0;
  flex-wrap: wrap;
}

.SubMenuItem li {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  flex-shrink: 0;
}

.SubMenuItem li.-spaceAvailable {
  color: #669601;
}

.SubMenuItem li + li::before {
  content: "•";
  display: inline-block;
  margin: 0 5px;
}
